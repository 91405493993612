import React from 'react';
import Layout from '../components/Layout';
import { Link } from 'gatsby';
import { withPrefix } from 'gatsby';
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

const NotFoundPage = () => (
  <Layout pageType="DetailsPage">
    <div className="main error-page">
      <section>
        <div className="image-wrapper">
          <PreviewCompatibleImage
            imageInfo={{
              image: `${withPrefix('/')}img/hero-grid-yellow.png`,
              alt: "a grid fo plus icons"
            }}
          />
        </div>
      </section>
      <section>
        <div className="container content">
          <div className="content-wrapper">
            <div className="text-container">
              <h1>Page not found</h1>
              <p>
              Nope, we've taken a good look and can't seem to find that page, sorry.
              </p>
            </div>
            <div className="find-out-more">
              <Link className="button button-more" to="/blog">
                Check our blog
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default NotFoundPage;
